import { Component } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'ei-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.scss']
})
export class DeleteAccountComponent {
  email: string = '';
  submitted: boolean = false;
  emailSentSuccessfully: boolean = false;
  emailError: string = ''; // Variable to hold the error message

  constructor(private functions: AngularFireFunctions) { }

  async onDeleteAccount() {
    try {
      this.submitted = true;
      const functionPrefix = environment.production ? "prodDeleteAccount" : "testDeleteAccount";
      await this.functions.httpsCallable(`${functionPrefix}-deleteAccount`)({ email: this.email }).toPromise();
      this.emailSentSuccessfully = true;
    } catch (error) {
      console.error('Error requesting account deletion:', error);
      // Update the error message to be displayed in the UI
      this.emailError = 'An error occurred while sending the email. Please try again later.';
    } finally {
      this.submitted = true;
    }
  }
}
