import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { AuthRoutingModule } from './auth-routing.module';
import { NbAuthModule } from '@nebular/auth';
import { NbAlertModule, NbButtonModule, NbCardModule, NbCheckboxModule, NbInputModule } from '@nebular/theme';

import { NgxLoginComponent } from './login/login.component'; // <---
import { NgxRegisterComponent } from './register/register.component';
import { ForgottenPasswordComponent } from './forgotten-password/forgotten-password.component';
import { ActionComponent } from './action/action.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { DeleteAccountComponent } from './delete-account/delete-account.component';
import { ConfirmAccountDeletionComponent } from './confirm-account-deletion/confirm-account-deletion.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NbAlertModule,
    NbInputModule,
    NbButtonModule,
    NbCheckboxModule,
    AuthRoutingModule,
    NbAuthModule,
    NbCardModule
  ],
  declarations: [
    NgxLoginComponent,
    NgxRegisterComponent,
    ForgottenPasswordComponent,
    DeleteAccountComponent,
    ConfirmAccountDeletionComponent,
    VerifyEmailComponent,
    ActionComponent
  ],
})
export class AuthModule {
}
