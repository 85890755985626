import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FireStoreService } from './firestore.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class LookupDataService extends FireStoreService<any> {
    constructor(_firestore: AngularFirestore) {
        super(_firestore, 'lookupData');
    }

    getIndustries(): Observable<Array<string>> {
         return this
          .getDoc("industries")
          .pipe(map(x => {
            return (<Array<string>>x.values).sort();
          }));
    }
}
