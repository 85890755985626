import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularFireFunctions } from '@angular/fire/functions';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-confirm-account-deletion',
  templateUrl: './confirm-account-deletion.component.html',
  styleUrls: ['./confirm-account-deletion.component.scss']
})
export class ConfirmAccountDeletionComponent implements OnInit {
  token: string;
  email: string;
  deletionSuccess: boolean = false;

  constructor(private route: ActivatedRoute, private functions: AngularFireFunctions) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.token = params['token'];
      this.email = params['email'];
      if (this.token && this.email) {
        this.confirmAccountDeletion();
      }
    });
  }

  async confirmAccountDeletion() {
    try {
      const functionPrefix = environment.production ? "prodDeleteAccount" : "testDeleteAccount";
      const confirmAccountDeletion = this.functions.httpsCallable(`${functionPrefix}-confirmAccountDeletion`);
      const result = await confirmAccountDeletion({ email: this.email, verificationToken: this.token }).toPromise();
      this.deletionSuccess = true; // Set deletionSuccess to true upon successful deletion
    } catch (error) {
      console.error('Error deleting account:', error);
      // You can handle this error in the UI as needed
      // For simplicity, you can just log the error
      // Alternatively, you can update a variable to display a specific error message in the UI
    }
  }
}
