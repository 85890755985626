export interface IAdvert {
    id: string;
    manufacturer: string;
    manufacturerId: string;
    name: string;
    coverImageFilename: string;
    coverImageUrl: string;
    images: Array<string>;
    category: string;
    likes: number;
    isLike: boolean;
    isFavorite: boolean;
    shortDescription: string;
    description: string;
    logo: string;
    features: Array<string>;
    benefits: Array<string>;
    applications: string;
    relatedAdverts: Array<IAdvert>;
    documents: Array<any>;
    isHorizontalLogo: boolean;
    isHorizontalCoverImage: boolean;
    publishDate: Date;
    keywords: Array<string>;
}

export class Advert implements IAdvert {
    id: string;
    manufacturer: string;
    manufacturerId: string
    name: string;
    images: Array<string>;
    category: string;
    likes: number;
    isLike: boolean;
    isFavorite: boolean;
    description: string;
    shortDescription: string;
    features: Array<string>;
    benefits: Array<string>;
    applications: string;
    relatedAdverts: Array<IAdvert>;
    documents: Array<any>;
    logo: string;

    coverImageFilename: string;
    coverImageUrl: string;
    isHorizontalLogo: boolean;
    isHorizontalCoverImage: boolean;
    publishDate: Date;
    keywords: Array<string>;

    constructor(id?: string, options?: any) {
        this.id = id;

        if (options) {
            this.name = options.name;
            this.applications = options.applications;
            this.benefits = options.benefits;
            this.category = options.category;
            this.description = options.description;
            this.features = options.features;
            this.manufacturer = options.manufacturer;
            this.manufacturerId = options.manufacturerId
            this.relatedAdverts = options.relatedAdverts;
            this.shortDescription = options.shortDescription;
            this.documents = options.documents;
            this.coverImageFilename = options.coverImageFilename;
            this.coverImageUrl = options.coverImageUrl;
            this.isHorizontalLogo = options.isHorizontalLogo;
            this.isHorizontalCoverImage = options.isHorizontalCoverImage;
            this.publishDate = options.publishDate.toDate();;
            this.keywords = options.keywords;
        }
    }
}
