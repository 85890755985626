import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NbAuthService, NbAuthToken } from '@nebular/auth';
import { HotToastService } from '@ngneat/hot-toast';
import _ from 'lodash';
import { combineLatest, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { UserProfile } from '../../models/user-profile';
import { LookupDataService } from '../../services/lookup-data.service';
import { UserService } from '../../services/users.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ei-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  isSaving: boolean = false;
  userProfile: UserProfile;
  industryOptions: Array<string> = [];
  currentUserId: string;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _toastService: HotToastService,
    private _lookupDataService: LookupDataService,
    private _authService: NbAuthService,

    private _userService: UserService) { }

  ngOnInit(): void {
    const userObs$ = this._authService.getToken()
      .pipe(switchMap((token: NbAuthToken) => {
        this.currentUserId = token.getPayload().user_id;
        return this._userService.getAsMapped(token.getPayload().user_id)
          .pipe(map(u => {
            return u;
          }));
      }));

    const industryObs$ = this._lookupDataService.getIndustries();

    combineLatest([userObs$, industryObs$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([userProfile, industries]) => {

        this.industryOptions = industries;

        let loadedProfile: UserProfile;

        if (userProfile) {
          loadedProfile = userProfile;
          loadedProfile.isNew = false;
        } else {
          loadedProfile = new UserProfile(this.currentUserId);
        }

        this.userProfile = loadedProfile;
      }, err => {
        console.error(err);
        throw err;
      });
  }

  changeIndustry($event: any) {
    console.dir($event);
  }

  async submit(): Promise<void> {
    let navigateHome = true;
    this.isSaving = true;
    try {
      if (this.userProfile.isNew) {
        this.userProfile.accountId = this.currentUserId;
        await this._userService.createDoc(this.userProfile, 'accountId');

      } else {
        await this._userService.updateDoc(this.userProfile.accountId, this.userProfile);
        navigateHome = false;
      }

      this._toastService.success('Profile updated successfully!');

      if(navigateHome){
        await this.router.navigate(['../../'], {relativeTo: this.route, replaceUrl: true });
      }
    } catch (ex: any) {
      this._toastService.error('Profile changes failed to save');
    } finally {
      this.isSaving = false;
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
