export class UserProfile {
  accountId: string;
  emailAddress: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  industry: string;
  employer: string;
  isNew; boolean;

  constructor(accountId?: string) {
    this.accountId = accountId;
    this.isNew = true;
}
}
