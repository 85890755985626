<img class="logo" src="assets/images/engineer-insight-logo.png" width=120>
<nb-card>
  <nb-card-body>

    <!-- Password Recovery -->
 <ng-container *ngIf="mode == 'resetPassword'">
      <h4>Password recovery</h4>
      <hr>
      <ng-container *ngIf="!submitted">
        <form (ngSubmit)="submitNewPassword()" #form="ngForm" aria-labelledby="title">

          <div class="form-control-group">
            <label class="label" for="input-password">New password:</label>
            <input nbInput [(ngModel)]="newPassword" #inputNewPassword="ngModel" type="password" id="input-password" name="password"
              placeholder="Password" fullWidth fieldSize="large"
              [status]="inputNewPassword.dirty ? (inputNewPassword.invalid  ? 'danger' : 'success') : 'basic'"
              required pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
              [attr.aria-invalid]="inputNewPassword.invalid && inputNewPassword.touched ? true : null">
            <ng-container *ngIf="inputNewPassword.invalid && inputNewPassword.touched">
              <p class="caption status-danger" *ngIf="inputNewPassword.errors?.required">
                Password is required!
              </p>
              <p class="caption status-danger" *ngIf="inputNewPassword.errors?.pattern" >
                Password should contain 8 characters including 1 uppercase, 1 lowercase and 1 number
                characters
              </p>
            </ng-container>
          </div>

          <div class="form-control-group">
            <label class="label" for="input-re-password">Confirm password:</label>
            <input nbInput [(ngModel)]="passwordConfirmation" #rePass="ngModel" type="password" id="input-re-password"
              name="rePass" placeholder="Confirm Password" fullWidth fieldSize="large"
              [status]="rePass.dirty ? (rePass.invalid ? 'danger' : 'success') : 'basic'"
              required
              [attr.aria-invalid]="rePass.invalid && rePass.touched ? true : null">
            <ng-container *ngIf="rePass.invalid && rePass.touched">
              <p class="caption status-danger" *ngIf="rePass.errors?.required">
                Password confirmation is required!
              </p>
            </ng-container>
          </div>

          <button nbButton fullWidth status="primary" size="large" [disabled]="submitted || !form.valid"
            [class.btn-pulse]="submitted">
            reset password
          </button>
        </form>
      </ng-container>

      <ng-container *ngIf="submitted">
        <p>Password successfully reset. Return to <a [routerLink]="" (click)="backToLogin()">login</a></p>
      </ng-container>
    </ng-container>

      <!-- Email verification -->
 <ng-container *ngIf="mode == 'verifyEmail'">
  <h4>Email address verified</h4>
  <hr>

  <p>Email address verification successful, close this tab and continue.</p>
</ng-container>

  </nb-card-body>
</nb-card>
