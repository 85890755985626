import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { HotToastService } from '@ngneat/hot-toast';

@Component({
  selector: 'ei-forgotten-password',
  templateUrl: './forgotten-password.component.html',
  styleUrls: ['./forgotten-password.component.scss']
})
export class ForgottenPasswordComponent implements OnInit {
  emailAddress: string = '';
  submitted: boolean;

  constructor(private _toastService: HotToastService, private afAuth: AngularFireAuth) { }

  ngOnInit(): void {
  }

  async sendForgottenPasswordEmail(): Promise<void> {
    try{
      await this.afAuth.sendPasswordResetEmail(this.emailAddress, { url: 'https://localhost:4200' });
      this.submitted = true;
    } catch(ex) {
      if(ex.code == 'auth/user-not-found') {
        this._toastService.error('User corresponding to the given email address does not exist.');
      }
    }
  }
}
