<img class="logo" src="assets/images/engineer-insight-logo.png" width=120>
<div class="row">
  <div class="column"></div>
</div>
<nb-card size="small">
  <nb-card-body>
    <h4>Email verification</h4>
    <hr>
    <p>An email has been sent. Please click the link when you get it.</p>
  </nb-card-body>
</nb-card>
