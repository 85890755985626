<div class="header-container">
  <a style="text-decoration:none;" href="/">
    <div class="logo-container">
      <img style="height:100%;" src="assets/images/engineer-insight-logo-no-bg.png"/>

    <span class="logo hidden-mobile">Engineers Insight</span>
  </div>
  </a>

</div>

<div class="header-container">
  <nb-actions size="small">

    <nb-action class="control-item">

      <form class="example-form" (submit)="globalSearch()">
        <mat-form-field class="example-full-width">
          <input [(ngModel)]="searchTerm" name="global-search-input" matInput autocomplete="off">
            <mat-icon (click)="globalSearch()" style="cursor:pointer; color:white;" matSuffix>search</mat-icon>
        </mat-form-field>
      </form>

    </nb-action>

    <nb-action
    class="control-item mobile-action"
    icon="search-outline"
    link="search"
    matRipple
    [matRippleUnbounded]="true"
    [matRippleCentered]="true"
  >Search</nb-action>

    <nb-action
      class="control-item mobile-action"
      icon="email-outline"
      link="enquiries"
      matRipple
      [matRippleUnbounded]="true"
      [matRippleCentered]="true"
    >My enquiries</nb-action>
    <nb-action
      class="control-item"
      icon="bell-outline"
      matRipple
      [matRippleUnbounded]="true"
      [matRippleCentered]="true"
    ></nb-action>
    <nb-action
      class="user-action"
      *nbIsGranted="['view', 'user']"
      matRipple
      [matRippleUnbounded]="true"
      [matRippleCentered]="true">
      <nb-user [nbContextMenu]="userMenu"
               [name]="user?.name">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
