import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserProfile } from '../models/user-profile';
import { User } from '../@core/data/users';
import { FireStoreService } from './firestore.service';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class UserService extends FireStoreService<UserProfile> {
    $currentUser: BehaviorSubject<UserProfile> = new BehaviorSubject<UserProfile>(null);

    constructor(_firestore: AngularFirestore) {
        super(_firestore, 'users');
    }

    getCurrentUser(userId: string) : Observable<UserProfile> {
      this.getAsMapped(userId)
      .subscribe(u => {
        this.$currentUser.next(u);
      });

      return this.$currentUser;
    }
}
