<div class="delete-account-page">
  <img class="logo" src="assets/images/engineer-insight-logo.png" width="120">

  <nb-card>
    <nb-card-body>
      <h4>Delete Account</h4>
      <hr>

      <ng-container *ngIf="!emailSentSuccessfully && !emailError">
        <form (ngSubmit)="onDeleteAccount()" #form="ngForm">

          <div class="form-control-group">
            <label class="label" for="input-email">Email address:</label>
            <input nbInput [(ngModel)]="email" #inputEmail="ngModel" type="email" id="input-email" name="email"
              placeholder="Email Address" fullWidth fieldSize="large"
              [status]="inputEmail.dirty ? (inputEmail.invalid ? 'danger' : 'success') : 'basic'" required
              pattern="[^ @]*@[^ @]*">
            <ng-container *ngIf="inputEmail.invalid && inputEmail.touched">
              <p class="caption status-danger" *ngIf="inputEmail.errors?.required">
                Email is required!
              </p>
              <p class="caption status-danger" *ngIf="inputEmail.errors?.pattern">
                Please enter a valid email address.
              </p>
            </ng-container>
          </div>

          <button nbButton fullWidth status="primary" size="large" [disabled]="submitted || form.invalid">
            <ng-container *ngIf="!submitted; else submittedContent">
              Delete Account
            </ng-container>
            <ng-template #submittedContent>
              Deleting...
            </ng-template>
          </button>
        </form>
      </ng-container>

      <ng-container *ngIf="emailSentSuccessfully">
        <p>A confirmation email has been sent to your inbox. Please check it to proceed with account deletion.</p>
      </ng-container>
      
      <nb-alert *ngIf="emailError" status="danger">
        {{ emailError }}
      </nb-alert>

    </nb-card-body>
  </nb-card>
</div>
