import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';

@Component({
  selector: 'verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit, OnDestroy {

  pollTimer: NodeJS.Timeout;
  constructor(private _router: Router, private _afAuth: AngularFireAuth) { }


  async ngOnInit(): Promise<void> {
    let currentUser = await this._afAuth.currentUser;

    await currentUser.sendEmailVerification();

    this.pollTimer = setInterval(async () => {
      await currentUser.reload();
      if(currentUser.emailVerified) {
        this.continueUserNavigation();
        clearInterval(this.pollTimer);
      }
    }, 2000)
  }

  continueUserNavigation() {
    this._router.navigate(['account-setup', 'user-profile'])
  }

  ngOnDestroy(): void {
    clearInterval(this.pollTimer);
  }
}
