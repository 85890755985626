import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import _ from 'lodash';
import { Observable, BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { Advert, IAdvert } from '../models/advert.model';
import { IManufacturer, Manufacturer } from '../models/manufacturer.model';
import { FireStoreService } from './firestore.service';
import { ManufacturerService } from './manufacturer.service';

@Injectable()
export class AdvertService extends FireStoreService<IAdvert> {

  $adverts: BehaviorSubject<Array<IAdvert>> = new BehaviorSubject<Array<IAdvert>>([]);
  _adverts: Array<IAdvert> = [];
  _currentManufacturers: Array<IManufacturer> = [];
  $currentAdvert: BehaviorSubject<IAdvert> = new BehaviorSubject<IAdvert>(null);

  constructor(_firestore: AngularFirestore, private _manufacturerService: ManufacturerService) {
    super(_firestore, 'adverts');

    this.$adverts.subscribe(adObs => {
      this._adverts = adObs;
    });
  }

  getAllAdverts(): Observable<Array<IAdvert>> {
    this.getAll(x =>
      x.where('published', '<=', true)
        .where('published', '>=', true)
        .orderBy('published', 'asc')
        .orderBy('publishDate', 'desc'))
      .subscribe(ads => {
        const adverts = ads.map(advertDoc => {
          const advert = new Advert(advertDoc.data.docId, advertDoc.data);
          return advert;
        });

        this._currentManufacturers = this._manufacturerService.manufacturers;
        if (!this._currentManufacturers || !this._currentManufacturers.length) {
          this._manufacturerService.getAllManufacturersObs()
            .subscribe(manufacturers => {
              this.setManufacturerInfo(adverts, manufacturers);
              this.$adverts.next(adverts);
            });
        } else {
          this.setManufacturerInfo(adverts, this._currentManufacturers);
          this.$adverts.next(adverts);
        }
      }
      );

    return this.$adverts;
  }

  getAdvertById(id: string): Observable<IAdvert> {
    return this.getDoc(id).pipe(
      switchMap((advertDoc: any) => {
        const advert = new Advert(advertDoc.docId, advertDoc);
        return this._manufacturerService.getDoc(advert.manufacturerId)
          .pipe(
            map(manufacturerDoc => {
              const manufacturer = new Manufacturer(manufacturerDoc.docId, manufacturerDoc)

              advert.isHorizontalLogo = manufacturer.isHorizontalLogo;
              advert.logo = manufacturer.logoFileUrl;
              return advert;
            })
          );
      }
      ));
  }

  getManufacturerPosts(manufacturerName: string): Observable<Array<IAdvert>> {
    return this.getAll(x =>
      x
        .where('manufacturer', '==', manufacturerName)
        .orderBy('publishDate', 'desc')
        .limit(3))
      .pipe(map(advertDocs => {
        return advertDocs.map(advertDoc => {
          const advert = new Advert(advertDoc.data.docId, advertDoc.data);

          return advert;
        })
      }));
  }

  search(searchTerm: Observable<string>): Observable<Array<Advert>> {
    return searchTerm
      .pipe(
        filter(term => term && term.length > 1),
        debounceTime(400),
        // distinctUntilChanged(),
        switchMap(term => {
          this.getAll(x =>
            x.where('published', '<=', true)
              .where('published', '>=', true)
              .where("searchTerms", "array-contains", term.toLowerCase().trim()))
            .subscribe(ads => {
              const adverts = ads.map(advertDoc => {
                const advert = new Advert(advertDoc.data.docId, advertDoc.data);
                return advert;
              });

              this._currentManufacturers = this._manufacturerService.manufacturers;
              if (!this._currentManufacturers || !this._currentManufacturers.length) {
                this._manufacturerService.getAllManufacturersObs()
                  .subscribe(manufacturers => {
                    this.setManufacturerInfo(adverts, manufacturers);
                    this.$adverts.next(adverts);
                  });
              } else {
                this.setManufacturerInfo(adverts, this._currentManufacturers);
                this.$adverts.next(adverts);
              }
            }
            );

          return this.$adverts;
        }));
  }

  private setManufacturerInfo(adverts: Array<IAdvert>, manufacturers: Array<IManufacturer>) {
    adverts.forEach(advert => {
      const matchManufacturer = manufacturers.find(manufacturer => manufacturer.name === advert.manufacturer);
      if (matchManufacturer) {
        advert.logo = matchManufacturer.logoFileUrl;
        advert.isHorizontalLogo = matchManufacturer.isHorizontalLogo
      }
    });
  }
}
