<img class="logo" src="assets/images/engineer-insight-logo.png" width=120>
<nb-card size="small">
  <nb-card-body>

    <h4>Password recovery</h4>
    <hr>
    <ng-container *ngIf="!submitted">
      <form (ngSubmit)="sendForgottenPasswordEmail()" #form="ngForm" aria-labelledby="title">

        <div class="form-control-group">
          <label class="label" for="input-email">Email:</label>
          <input nbInput fullWidth [(ngModel)]="emailAddress" #email="ngModel" name="email" id="input-email"
            pattern=".+@.+\..+" placeholder="Email" fieldSize="large" autofocus
            [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : 'basic'" required
            [attr.aria-invalid]="email.invalid && email.touched ? true : null">
          <ng-container *ngIf="email.invalid && email.touched">
            <p class="caption status-danger" *ngIf="email.errors?.required">
              Please provide an email
            </p>
            <p class="caption status-danger" *ngIf="email.errors?.pattern">
              Please provide a valid email address
            </p>
          </ng-container>
        </div>

        <button nbButton fullWidth status="primary" size="large" [disabled]="submitted || !form.valid"
          [class.btn-pulse]="submitted">
          email me a recovery link
        </button>
      </form>
    </ng-container>

    <ng-container *ngIf="submitted">
      <p>An email has been sent. Please click the link when you get it.</p>
    </ng-container>

  </nb-card-body>
</nb-card>
