import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CoreModule } from './@core/core.module';
import { ThemeModule } from './@theme/theme.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {
  NbChatModule,
  NbDatepickerModule,
  NbDialogModule,
  NbMenuModule,
  NbSidebarModule,
  NbToastrModule,
  NbWindowModule,
} from '@nebular/theme';
import { environment } from '../environments/environment';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { AdvertService } from './services/advert.service';
import { ManufacturerService } from './services/manufacturer.service';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { HotToastModule } from '@ngneat/hot-toast';
import { AccountSetupModule } from './pages/account-setup/account-setup.module';
import { LookupDataService } from './services/lookup-data.service';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';

@NgModule({
  declarations: [AppComponent, PrivacyPolicyComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    NbChatModule.forRoot({
      messageGoogleMapKey: 'AIzaSyA_wNuCzia92MAmdLRzmqitRGvCF7wCZPY',
    }),
    CoreModule.forRoot(),
    ThemeModule.forRoot(),
    AngularFireModule.initializeApp({
      firestore: () => firebase.firestore().settings({experimentalForceLongPolling: true}),
      ...environment.firebase
    }),
    AngularFirestoreModule,
    AngularFireAuthModule,
    HotToastModule.forRoot(),
    AccountSetupModule
  ],
  bootstrap: [AppComponent],
  providers: [
    AdvertService,
    ManufacturerService,
    LookupDataService
  ]
})
export class AppModule {
}
