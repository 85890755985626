<div class="col-md-6">
  <nb-card *ngIf="userProfile">
    <nb-card-header>User profile</nb-card-header>
    <nb-card-body>
      <form (ngSubmit)="submit()">
        <div class="form-group">
          <label for="inputFirstName" class="label">First name</label>
          <input type="text" nbInput fullWidth id="inputFirstName" [(ngModel)]="userProfile.firstName" name="firstNameInput" placeholder="First name">
        </div>
        <div class="form-group">
          <label for="inputLastName" class="label">Last Name</label>
          <input type="text" nbInput fullWidth id="inputLastName" [(ngModel)]="userProfile.lastName" name="lastNameInput" placeholder="Last Name">
        </div>
        <div class="form-group">
          <label for="inputJobTitle" class="label">Job title</label>
          <input type="text" nbInput fullWidth id="inputJobTitle" [(ngModel)]="userProfile.jobTitle" name="jobTitleInput" placeholder="Job title">
        </div>
        <div class="form-group">
          <label for="inputEmployer" class="label">Employer</label>
          <input type="text" nbInput fullWidth id="inputEmployer" [(ngModel)]="userProfile.employer" name="employerInput" placeholder="Employer">
        </div>
        <div class="form-group">
          <label for="inputSelectIndustry" class="label">Industry</label>
          <nb-select matRipple id="inputSelectIndustry" placeholder="Choose an industry" fullWidth name="selectIndustryInput" [(ngModel)]="userProfile.industry" [ngModelOptions]="{standalone: true}" (selectedChange)="changeIndustry($event)" >
            <nb-option *ngFor="let industryOption of industryOptions" [value]="industryOption"> {{industryOption}}
            </nb-option>
          </nb-select>
        </div>
        <button type="submit" [disabled]="isSaving" nbButton status="primary">Submit</button>
      </form>
    </nb-card-body>
  </nb-card>
