export interface IManufacturer {
    isHorizontalLogo: boolean;
    logoFileUrl: string;
    id: string;
    name: string;
    logoFileName: string;
    createdDate: Date;
    updatedDate: Date;
}

export class Manufacturer implements IManufacturer {
    id: string;
    name: string;
    logoFileName: string;
    logoFileUrl: string;
    createdDate: Date;
    updatedDate: Date;
    isHorizontalLogo: boolean;

    constructor(id: string, manufacturerDoc: any) {
      this.id = id;
      this.name = manufacturerDoc.name;
      this.isHorizontalLogo = manufacturerDoc.isHorizontalLogo;
      this.logoFileName = manufacturerDoc.logoFileName;
      this.logoFileUrl = manufacturerDoc.logoFileUrl;
    }
}
