import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, BehaviorSubject } from 'rxjs';
import { IManufacturer, Manufacturer } from '../models/manufacturer.model';
import { FireStoreService } from './firestore.service';

@Injectable()
export class ManufacturerService extends FireStoreService<IManufacturer> {

    $manufacturers: BehaviorSubject<Array<IManufacturer>> = new BehaviorSubject<Array<IManufacturer>>([]);
    manufacturers: Array<IManufacturer> = [];

    constructor(_firestore: AngularFirestore) {
        super(_firestore, 'manufacturers');

        this.$manufacturers.subscribe(manufacturerObs => {
            this.manufacturers = manufacturerObs;
        });
    }

    getAllManufacturersObs(): Observable<Array<IManufacturer>> {
        this.getAll()
            .subscribe(ads => {
                this.$manufacturers.next(ads.map(ad => {
                    const manufacturer = new Manufacturer(ad.docId, ad.data);
                    return manufacturer;
                }));
            });
        return this.$manufacturers;
    }
}
