import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserProfileComponent } from '../user-profile/user-profile.component';
import { AccountSetupComponent } from './account-setup.component';

const routes: Routes = [
  {
    path: '',
    component: AccountSetupComponent,
    children: [
      {
        path: 'user-profile',
        component: UserProfileComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class AccountSetupRoutingModule { }
