import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference, QueryFn } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class FireStoreService<T> {
  collectionName: string;
  constructor(private _firestore: AngularFirestore, collectionName: string) {
    this.collectionName = `environments/${environment.environmentName}/${collectionName}`;
  }

  generateId() {
    return this._firestore.createId();
  }

  getAll(queryFn?: QueryFn): Observable<Array<any>> {
    return this._firestore.collection(this.collectionName, queryFn).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data();
        return { data, id: a.payload.doc.id };
      })),
    );
  }

  getDoc(id) {
    return this._firestore.doc<any>(`${this.collectionName}/${id}`).valueChanges();
  }

  getAsMapped(id): Observable<T>{
    return this._firestore.doc<T>(`${this.collectionName}/${id}`).valueChanges();
  }

  updateDoc(docId: string, doc: T): Promise<void> {
    return this._firestore.collection(this.collectionName).doc<T>(docId).update(Object.assign({}, doc));
  }

  createDoc(doc: T, idProp: string = "id"): Promise<void> {
    return this._firestore
      .collection(this.collectionName)
      .doc(doc[idProp])
      .set({ ...doc });
  }

  deleteDoc(docId: string): Promise<void> {
    return this._firestore.collection(this.collectionName).doc(docId).delete();
  }
}
