import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccountSetupRoutingModule } from './account-setup-routing.module';
import { AccountSetupComponent } from './account-setup.component';
import { ThemeModule } from '../../@theme/theme.module';
import { NbButtonModule, NbCardModule } from '@nebular/theme';


@NgModule({
  declarations: [
    AccountSetupComponent
  ],
  imports: [
    NbCardModule,
    NbButtonModule,
    ThemeModule,
    CommonModule,
    AccountSetupRoutingModule
  ]
})
export class AccountSetupModule { }
