<div class="delete-account-page">
  <img class="logo" src="assets/images/engineer-insight-logo.png" width="120">

  <nb-card *ngIf="!deletionSuccess">
    <nb-card-body>
      <h4>Account deleted</h4>
    </nb-card-body>
  </nb-card>
  
  <nb-card *ngIf="deletionSuccess" status="success" class="success-card">
    <nb-card-body>
      <div class="success-content">
        <div class="check-icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            <circle class="check-icon-circle" cx="26" cy="26" r="25" fill="none" />
            <path class="check-icon-check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
          </svg>
        </div>
        <h4>Account Successfully Deleted</h4>
        <p>Your account has been successfully deleted.</p>
        <!-- You can add additional content or actions here -->
      </div>
    </nb-card-body>
  </nb-card>
</div>
