import { NgModule } from '@angular/core';
import { AngularFireAuthGuard, redirectLoggedInTo } from '@angular/fire/auth-guard';
import { RouterModule, Routes } from '@angular/router';
import { NbAuthComponent, NbLogoutComponent, NbRegisterComponent } from '@nebular/auth';
import { NotFoundComponent } from '../pages/miscellaneous/not-found/not-found.component';
import { PrivacyPolicyComponent } from '../pages/privacy-policy/privacy-policy.component';
import { ActionComponent } from './action/action.component';
import { ForgottenPasswordComponent } from './forgotten-password/forgotten-password.component';
import { NgxLoginComponent } from './login/login.component';
import { NgxRegisterComponent } from './register/register.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { DeleteAccountComponent } from './delete-account/delete-account.component';
import { ConfirmAccountDeletionComponent } from './confirm-account-deletion/confirm-account-deletion.component';

const redirectLoggedInToHome = () => redirectLoggedInTo(['/']);

export const routes: Routes = [
  {
    path: '',
    component: NbAuthComponent,
    children: [
      {
        path: 'login',
        component: NgxLoginComponent,
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: redirectLoggedInToHome },
      },
      {
        path: 'register',
        component: NgxRegisterComponent,
      },
      {
        path: 'logout',
        component: NbLogoutComponent,
      },
      {
        path:'privacy-policy',
        component: PrivacyPolicyComponent
      },
      {
        path: 'forgotten-password',
        component: ForgottenPasswordComponent
      },
      {
        path: 'delete-account',
        component: DeleteAccountComponent
      },
      {
        path: 'confirm-account-deletion',
        component: ConfirmAccountDeletionComponent
      },
      {
        path: 'verify-email',
        component: VerifyEmailComponent
      },
      {
        path: 'action',
        component: ActionComponent
      },
      {
        path: '**',
        component: NotFoundComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {
}
