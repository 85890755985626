import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { HotToastService } from '@ngneat/hot-toast';
import _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ei-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.scss']
})
export class ActionComponent implements OnInit {

  private destroy$: Subject<void> = new Subject<void>();
  newPassword: string = '';
  passwordConfirmation: string = '';
  submitted: boolean;
  actionCodeChecked: boolean;
  actionCode: string;
  mode: string;

  constructor(
    private _toastService: HotToastService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _afAuth: AngularFireAuth) { }

   async ngOnInit(): Promise<void> {

    this._activatedRoute.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        // if we didn't receive any parameters,
        // we can't do anything
        if (_.isEmpty(params)) this._router.navigate(['../login']);

        this.mode = params['mode'];
        this.actionCode = params['oobCode'];

        if(this.mode == 'verifyEmail') {

          this._afAuth.checkActionCode(this.actionCode).then(acInfo => {
            this._afAuth.applyActionCode(this.actionCode).then(() => console.log('action code applied'));
          }).catch(err => {
            console.dir(err);
          })


        }

        else if (this.mode == 'resetPassword') {
          // Verify the password reset code is valid.
          this._afAuth.verifyPasswordResetCode(this.actionCode).then(email => {
            this.actionCodeChecked = true;
          }).catch(e => {
            // Invalid or expired action code. Ask user to try to reset the password
            // again.
            this._toastService.error('Password reset link invalid or expired, request another reset link.', {
              dismissible: true,
              autoClose: false,
            });
            this._router.navigate(['/auth/login']);
          });
        }
      });
  }

  async submitNewPassword(): Promise<void> {
    try {
      if (_.isEqual(this.newPassword, this.passwordConfirmation)) {
        await this._afAuth.confirmPasswordReset(this.actionCode, this.newPassword);
        this.submitted = true;
      } else {
        this._toastService.error('Ensure passwords match!');
      }
    } catch (ex) {
      alert(ex);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  backToLogin() {
    const operatingSystem = this.getMobileOperatingSystem();
    if (operatingSystem == "iOS") {
      this.launchAppOnAppleDevice();
    } else if (operatingSystem == "Android") {
      alert("android");
    } else {
      this._router.navigate(['/auth/login']);
    }
  }

  getMobileOperatingSystem(): string {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  }

  launchAppOnAndroidDevice() {
    const url = "intent://engineersinsight.co.uk/#Intent;scheme=comengineersinsightmobile;package=com.engineersinsight.mobile;end";
    window.location.replace(url);
  }

  launchAppOnAppleDevice() {
    const url = "comengineersinsightmobile://";
    window.location.replace(url);
 }
}
